<template>
    <transition appear enter-active-class="animated fadeIn">
        <div class="has-text-centered">
            <b-loading :is-full-page="true" :active="!content"/>

            <div class="level-item">
                <h1 class="title is-1 page-title">ABOUT US</h1>
            </div>
            <div class="box b-shdw-3">
                <p v-html="content.e1"/>
                <br>
                <h5 class="title is-4" v-html="content.e10"/>
                <p v-html="content.e11"/>
                <br>
                <h5 class="title is-4" v-html="content.e2"/>
                <p v-html="content.e3"/>
                <br>
                <h5 class="title is-4" v-html="content.e12"/>
                <p v-html="content.e13"/>
                <br>
                <h5 class="title is-4" v-html="content.e4"/>
                <p v-html="content.e5"/>
                <br>
                <h5 class="title is-4" v-html="content.e6"/>
                <p v-html="content.e7"/>
                <br>
                <h5 class="title is-4" v-html="content.e8"/>
                <p v-html="content.e9"/>
                <br>
            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "AboutUs",
        mixins: [ contentAPI, page ],
    }
</script>

<style lang="scss" scoped>
    h5 {
        color: $c2g_orange;
        margin-bottom: 8px !important;
        text-align: center;
    }
    .level-item {
        padding: 20px 0 40px 0;
        text-align: center;
    }
</style>